<!--填写完成 待支付-->
<template>
	<div class="consu-container">
		<div class="consultation">
			<div class="content-top">
				<i class="consultation-type-icon"></i>
			</div>
			<div class="content-user">
				<i :class="this.formData.gender === 1 ? 'head-icon-m' : 'head-icon'"></i>
				<div class="user-info-view">
					<div class="user-info-one">
						<span>{{this.formData.patientName}}</span>
						<span>{{this.formData.gender === 1 ? "男" : "女"}}</span>
						<span>{{this.formData.age}}岁</span>
					</div>
					<div class="user-info-two">
						<span>手机号</span>
						<span>{{this.formData.mobilePhone}}</span>
					</div>
				</div>
			</div>
			<div class="body-sign">
				<div class="sign-view">
					<span>体重</span>
					<span>{{this.formData.weight}}kg</span>
				</div>
				<div class="sign-view">
					<span>身高</span>
					<span>{{this.formData.height}}cm</span>
				</div>
			</div>
			<div class="dash-border"></div>
			<div class="disease-content">
				<div class="disease-item">
					<div class="item-title">疾病名称或症状</div>
					<div class="item-value">{{this.formData.diseaseNameOrzz}}</div>
				</div>
				<div class="disease-item">
					<div class="item-title">病情描述</div>
					<div class="item-value">{{this.formData.diseaseDescription}}</div>
				</div>
				<div class="disease-item">
					<div class="item-title">服用药物</div>
					<div class="item-value">{{this.formData.takedMedicine}}</div>
				</div>
				<div class="disease-item">
					<div class="item-title">过敏史</div>
					<div class="item-value">{{this.formData.guominshi}}</div>
				</div>
				<div class="disease-item">
					<div class="item-title">既往病史</div>
					<div class="item-value">{{this.formData.jiwangbingshi}}</div>
				</div>
				<div class="disease-item">
					<div class="item-title">婚育史</div>
					<div class="item-value">{{this.formData.hunyushi}}</div>
				</div>
				<div class="disease-item">
					<div class="item-title">问诊单</div>
					<div class="item-value row">
						<span>查看问诊单</span>
						<span @click="clickQuestionnaire">点击查看 <i /></span>
					</div>
				</div>
				<div class="disease-item">
					<div class="item-title">舌面照</div>
					<div class="item-value img-list">
						<van-uploader id="imglist" :deletable="false" class="pic-uploader" v-model="imgList" :max-count="imgList.length"></van-uploader>
					</div>
				</div>
			</div>
		</div>
		<div class="page-bottom" v-if="formData.status === 1">
			<div class="pay-info">
				<i />
				<div>
					<div>图文问诊</div>
					<div>￥{{formData.totalFee}}</div>
				</div>
			</div>
			<div class="pay-btn" @click="gotoPay">
				<span>去支付</span>
			</div>
		</div>
		<van-popup :style="{height: '90%'}" v-model="showPopup" position="bottom" style="background: #F3F3F3;">
			<div class="pop-content">
				<consultation ref="consultation" :disabled="true"></consultation>
			</div>
			<div class="pop-bottom" @click="hidePopup">
				<div>
					<span>取消</span>
				</div>
			</div>
		</van-popup>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import consultation from './consultation';
import { consultationDetail } from '@/request/api/user';
export default {
	data () {
		return {
			showPopup: false,
			showGoBack: false,
			canNext: false,
			patientInfo: {
				name: '',
				gender: '',
				age: 16,
				mobilePhone: '',
				height: 0,
				weight: 0
			},
			form1: {
				diseaseNameOrzz: '',
				diseaseDescription: '',
				takedMedicine: '',
				guominshi: '',
				jiwangbingshi: '',
				hunyushi: ''
			},
			imgListP: [],
			imgListR: [],
			imgList: [],
			formData: {
				totalFee: 0
			}
		};
	},
	components: {
		consultation
	},
	mounted () {
		// if (window.history && window.history.pushState) {
		// history.pushState(null, null, document.URL);
		// window.addEventListener('popstate', this.onBack, false);
		// }
		window.addEventListener('popstate', this.onBack, false);
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	beforeCreate () {
		this.$route.meta.title = '订单待支付';
	},
	computed: {
		...mapGetters({
			consultationValue: 'getStepConsultation',
			stepOneVal: 'getStepOne'
		})
	},
	created () {
		if (this.$route.query.id) {
			this.getDetail();
		} else {
			this.setPageData();
		}
	},
	methods: {
		onBack () {
			if (this.$route.query.isList) {
				this.$root.go('/user-center');
			} else {
				this.$root.go('/doctor-detail', { doctorId: this.$route.query.doctorId });
			}
		},
		setTitle () {
			document.title = `${this.$route.query.doctorName} ${this.$route.query.departmentName}`;
		},
		getDetail () {
			consultationDetail({ doctorId: this.$route.query.doctorId, patientId: this.$route.query.patientId, id: this.$route.query.id }).then(data => {
				if (data && data === 'retry') {
					this.getDetail();
				} else if (data) {
					// console.log('consultationDetail====', data);
					let qa = {};
					if (data.consultationAnswer) {
						qa = JSON.parse(data.consultationAnswer);
					}
					this.$store.commit('setStep', {
						step: 'stepTwo',
						obj: { qa: qa, consultationType: data.consultationType }
					});
					this.$store.commit('setStep', {
						step: 'stepConsultation',
						obj: { consultation: data }
					});
					this.setPageData();
				} else {
					this.$root.replace('/');
				}
			});
		},
		setPageData () {
			if (this.stepOneVal && this.stepOneVal.patientInfo) {
				this.patientInfo = this.stepOneVal.patientInfo;
				this.form1 = this.stepOneVal.form1;
				this.canNext = true;
			}
			this.imgList = [];
			if (this.consultationValue && this.consultationValue.consultation) {
				this.formData = { ...this.consultationValue.consultation };
				if (this.formData.tongue1) {
					this.imgList.push({ url: this.formData.tongue1 });
					// this.imgListP.push({ url: this.formData.tongue1 });
					// this.imgListR.push({ url: `${this.formData.tongue1}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.tongue2) {
					this.imgList.push({ url: this.formData.tongue2 });
					// this.imgListP.push({ url: this.formData.tongue2 });
					// this.imgListR.push({ url: `${this.formData.tongue2}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.tongue3) {
					this.imgList.push({ url: this.formData.tongue3 });
					// this.imgListP.push({ url: this.formData.tongue3 });
					// this.imgListR.push({ url: `${this.formData.tongue3}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.tongue4) {
					this.imgList.push({ url: this.formData.tongue4 });
					// this.imgListP.push({ url: this.formData.tongue4 });
					// this.imgListR.push({ url: `${this.formData.tongue4}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.tongue5) {
					this.imgList.push({ url: this.formData.tongue5 });
					// this.imgListP.push({ url: this.formData.tongue5 });
					// this.imgListR.push({ url: `${this.formData.tongue5}?x-oss-process=image/resize,w_60` });
				}

				if (this.formData.face1) {
					this.imgList.push({ url: this.formData.face1 });
					// this.imgListP.push({ url: this.formData.face1 });
					// this.imgListR.push({ url: `${this.formData.face1}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.face2) {
					this.imgList.push({ url: this.formData.face2 });
					// this.imgListP.push({ url: this.formData.face2 });
					// this.imgListR.push({ url: `${this.formData.face2}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.face3) {
					this.imgList.push({ url: this.formData.face3 });
					// this.imgListP.push({ url: this.formData.face3 });
					// this.imgListR.push({ url: `${this.formData.face1}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.face4) {
					this.imgList.push({ url: this.formData.face4 });
					// this.imgListP.push({ url: this.formData.face4 });
					// this.imgListR.push({ url: `${this.formData.face1}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.face5) {
					this.imgList.push({ url: this.formData.face5 });
					// this.imgListP.push({ url: this.formData.face5 });
					// this.imgListR.push({ url: `${this.formData.face5}?x-oss-process=image/resize,w_60` });
				}

				if (this.formData.mr1) {
					this.imgList.push({ url: this.formData.mr1 });
					// this.imgListP.push({ url: this.formData.mr1 });
					// this.imgListR.push({ url: `${this.formData.mr1}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.mr2) {
					this.imgList.push({ url: this.formData.mr2 });
					// this.imgListP.push({ url: this.formData.mr2 });
					// this.imgListR.push({ url: `${this.formData.mr2}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.mr3) {
					this.imgList.push({ url: this.formData.mr3 });
					// this.imgListP.push({ url: this.formData.mr3 });
					// this.imgListR.push({ url: `${this.formData.mr3}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.mr4) {
					this.imgList.push({ url: this.formData.mr4 });
					// this.imgListP.push({ url: this.formData.mr4 });
					// this.imgListR.push({ url: `${this.formData.mr4}?x-oss-process=image/resize,w_60` });
				}
				if (this.formData.mr5) {
					this.imgList.push({ url: this.formData.mr5 });
					// this.imgListP.push({ url: this.formData.mr5 });
					// this.imgListR.push({ url: `${this.formData.mr5}?x-oss-process=image/resize,w_60` });
				}
				// this.imgList = [...this.imgListP];
				this.setTitle();
			} else {
				this.$root.replace('/');
			}
		},
		clickQuestionnaire () {
			this.showPopup = true;
		},
		hidePopup () {
			this.showPopup = false;
		},
		gotoPay () {
			this.$root.go('/payment/', {
				orderId: this.formData.orderId,
				type: 0,
				totalFee: this.formData.totalFee
			});
			// setTimeout(() => {
			// window.location.reload();
			// }, 100);
		}
	}
};
</script>
<style lang="less" scoped>
	.consu-container {
		display: flex;
		flex-direction: column;
	}
	.consultation {
		background-color: #ffffff;
		margin: 10px 12px;
		border-radius: 8px;
		padding-bottom: 29px;
		margin-bottom: 127px;
	}
	.content-top {
		display: flex;
		justify-content: flex-end;
	}
	.consultation-type-icon {
		background-image: url('../../assets/images/consultation-type-icon.png');
		width: 60px;
		height: 21px;
		background-size: 100% 100%;
	}
	.content-user {
		padding: 0 15px;
		display: flex;
		align-items: center;
		margin-top: 8px;
	}
	.head-icon {
		background-image: url('../../assets/images/patient-w.png');
		width: 48px;
		height: 48px;
		background-size: 100% 100%;
	}
	.head-icon-m {
		background-image: url('../../assets/images/patient-m.png');
		width: 48px;
		height: 48px;
		background-size: 100% 100%;
	}
	.user-info-view {
		display: flex;
		flex-direction: column;
	}
	.user-info-one {
		font-size: 19px;
    color: #232830;
	}
	.user-info-one > span {
		margin: 0 10px;
	}
	.user-info-two {
		font-size: 13px;
		color: #8B8B8B;
		margin-top: 10px;
	}
	.user-info-two > span {
		margin-left: 10px;
	}
	.body-sign {
		background-color: #f3f3f3;
		display: flex;
		align-items: center;
		margin: 19px 15px 0 15px;
		padding: 10px 0;
	}
	.sign-view {
		margin: 0 12px;
		font-size: 13px;
		color: #7d7d7d;
	}
	.sign-view > span:last-child {
		margin-left: 12px;
	}
	.dash-border {
		border-bottom: 1px dashed #47A7FF;
		margin: 30px 15px 0 15px;
	}
	.disease-content {
		margin: 0 15px;
	}
	.disease-item {
		margin-top: 24px;
	}
	.item-title {
		font-size: 13px;
		color: #7D7D7D;
		line-height: 13px;
	}
	.item-value {
		font-size: 15px;
		color: #232830;
		margin-top: 10px;
		line-height: 25px;
		word-break: break-word;
	}
	.item-value.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.item-value.row>span:last-child {
		font-size: 13px;
		color: #FF6450;
		display: flex;
		align-items: center;
		line-height: 13px;
	}
	.item-value.row>span:last-child>i {
		background-image: url('../../assets/images/right-arrow-blue.png');
		width: 8px;
		height: 13px;
		background-size: 100% 100%;
		margin-left: 5px;
	}
	.tongue-photo {
		width: 72px;
		height: 72px;
		margin-right: 4px;
		margin-top: 3px;
	}
	.page-bottom {
		position: fixed;
		background-color: #ffffff;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
		box-shadow: 0 0 3px #dedede;
	}
	.pay-info {
		margin-left: 14px;
		display: flex;
		align-items: center;
	}
	.pay-info > i {
		background-image: url('../../assets/images/service-2.png');
		width: 38px;
		height: 38px;
		background-size: 100% 100%;
	}
	.pay-info > div {
		margin-left: 10px;
		font-family: PingFangSC-Regular, PingFang SC;
	}
	.pay-info > div > div:first-child {
		font-size: 14px;
		font-weight: 500;
		color: #232830;
		line-height: 16px;
	}
	.pay-info > div > div:last-child {
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #F33D3A;
		line-height: 14px;
		margin-top: 5px;
	}
	.pay-btn {
		margin-right: 12px;
		background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
		border-radius: 20px;
		padding: 12px 36px;
	}
	.pay-btn > span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 14px;
	}
	.pop-content {
		padding-bottom: 74px;
	}
	.pop-bottom {
		position: fixed;
		width: 100%;
		padding: 10px 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #FFFFFF;
		box-shadow: 0 0 3px #dedede;
		div {
			// background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			// border-radius: 20px;
			padding: 9px 0;
			margin: 0 14px;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				font-size: 15px;
				font-weight: 400;
				color: #3B3D40;
				line-height: 12px;
			}
		}
	}
	/deep/.van-uploader__preview .van-uploader__preview-image {
		width: 60px;
		height: 60px;
	}
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consu-container" },
    [
      _c("div", { staticClass: "consultation" }, [
        _vm._m(0),
        _c("div", { staticClass: "content-user" }, [
          _c("i", {
            class: this.formData.gender === 1 ? "head-icon-m" : "head-icon",
          }),
          _c("div", { staticClass: "user-info-view" }, [
            _c("div", { staticClass: "user-info-one" }, [
              _c("span", [_vm._v(_vm._s(this.formData.patientName))]),
              _c("span", [
                _vm._v(_vm._s(this.formData.gender === 1 ? "男" : "女")),
              ]),
              _c("span", [_vm._v(_vm._s(this.formData.age) + "岁")]),
            ]),
            _c("div", { staticClass: "user-info-two" }, [
              _c("span", [_vm._v("手机号")]),
              _c("span", [_vm._v(_vm._s(this.formData.mobilePhone))]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "body-sign" }, [
          _c("div", { staticClass: "sign-view" }, [
            _c("span", [_vm._v("体重")]),
            _c("span", [_vm._v(_vm._s(this.formData.weight) + "kg")]),
          ]),
          _c("div", { staticClass: "sign-view" }, [
            _c("span", [_vm._v("身高")]),
            _c("span", [_vm._v(_vm._s(this.formData.height) + "cm")]),
          ]),
        ]),
        _c("div", { staticClass: "dash-border" }),
        _c("div", { staticClass: "disease-content" }, [
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [
              _vm._v("疾病名称或症状"),
            ]),
            _c("div", { staticClass: "item-value" }, [
              _vm._v(_vm._s(this.formData.diseaseNameOrzz)),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("病情描述")]),
            _c("div", { staticClass: "item-value" }, [
              _vm._v(_vm._s(this.formData.diseaseDescription)),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("服用药物")]),
            _c("div", { staticClass: "item-value" }, [
              _vm._v(_vm._s(this.formData.takedMedicine)),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("过敏史")]),
            _c("div", { staticClass: "item-value" }, [
              _vm._v(_vm._s(this.formData.guominshi)),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("既往病史")]),
            _c("div", { staticClass: "item-value" }, [
              _vm._v(_vm._s(this.formData.jiwangbingshi)),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("婚育史")]),
            _c("div", { staticClass: "item-value" }, [
              _vm._v(_vm._s(this.formData.hunyushi)),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("问诊单")]),
            _c("div", { staticClass: "item-value row" }, [
              _c("span", [_vm._v("查看问诊单")]),
              _c("span", { on: { click: _vm.clickQuestionnaire } }, [
                _vm._v("点击查看 "),
                _c("i"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "disease-item" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("舌面照")]),
            _c(
              "div",
              { staticClass: "item-value img-list" },
              [
                _c("van-uploader", {
                  staticClass: "pic-uploader",
                  attrs: {
                    id: "imglist",
                    deletable: false,
                    "max-count": _vm.imgList.length,
                  },
                  model: {
                    value: _vm.imgList,
                    callback: function ($$v) {
                      _vm.imgList = $$v
                    },
                    expression: "imgList",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.formData.status === 1
        ? _c("div", { staticClass: "page-bottom" }, [
            _c("div", { staticClass: "pay-info" }, [
              _c("i"),
              _c("div", [
                _c("div", [_vm._v("图文问诊")]),
                _c("div", [_vm._v("￥" + _vm._s(_vm.formData.totalFee))]),
              ]),
            ]),
            _c("div", { staticClass: "pay-btn", on: { click: _vm.gotoPay } }, [
              _c("span", [_vm._v("去支付")]),
            ]),
          ])
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticStyle: { background: "#F3F3F3" },
          style: { height: "90%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pop-content" },
            [
              _c("consultation", {
                ref: "consultation",
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pop-bottom", on: { click: _vm.hidePopup } },
            [_c("div", [_c("span", [_vm._v("取消")])])]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-top" }, [
      _c("i", { staticClass: "consultation-type-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }